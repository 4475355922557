import React, { useState } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import { Anchor, useResolution, Button, breakpoints, resetPassword, showToast } from '@ecommerce/shared'
import { Icon } from '../../components/Icon/Icon'
import Layout from '../../components/Layout'
import { PageContainer } from '../../components/PageContainer'
import { PgPageProps } from '../../types/PgPages'
import withPageTransition from '../../components/withPageTransition'
import { UserMenuMobileNavbar } from '../../components/NavBar/UserMenuMobileNavbar'
import NavBarStaticPage from '../../components/NavBar/NavBarStaticPage'

const cssPrefix = `NewPassword__`

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .${cssPrefix} {
    &email-image {
      margin-bottom: 30px;
      width: 150px;
      height: 150px;
    }
    &indication {
      font-size: 1.07em;
      margin-bottom: 37px;
      text-align: center;
      color: ${(props) => props.theme.colors.black};
    }
    &top-bar {
      text-align: center;
      margin-bottom: 27px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      &-icon {
        width: 30px;
        cursor: pointer;
      }
      &-title {
        text-align: center;
        flex-grow: 1;
        @media (${breakpoints.desktop.min}) {
          padding-right: 30px;
        }
      }
    }
    &container {
      width: 350px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .${cssPrefix} {
        &form {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-current-password {
            margin-bottom: 30px;
          }
          &-new-password {
            margin-bottom: 20px;
          }
          &-confirm-new-password {
            margin-bottom: 80px;
          }
          &-submit-button {
            width: 250px;
            margin-bottom: 15px;
          }
          &-submit-button-white {
            width: 250px;
            min-height: 40px;
            background: none;
            color: ${({ theme }) => theme.colors.primary};
            border: 1px solid ${({ theme }) => theme.colors.primary};
          }
        }
        &status-error {
          display: flex;
          justify-content: center;
          width: 100%;
          &-message {
            width: 250px;
            color: ${(props) => props.theme.colors.error};
            svg {
              fill: ${(props) => props.theme.colors.error};
            }
          }
        }
      }
    }
  }
`

const ResetPassword = (props: PgPageProps) => {
  const { currentCity } = props.pageContext
  const currentSlug = currentCity && currentCity.slug ? currentCity.slug : 'vina-del-mar'
  const { isDesktop } = useResolution()
  const [loading, setLoading] = useState(false)

  const onSubmit = async () => {
    try {
      setLoading(true)
      await resetPassword(props?.location?.state?.payload)
      showToast({
        content: `Por favor revisa tu bandeja de entrada para continuar.`,
        title: `¡Se ha reenviado el correo con éxito!`,
        type: 'success',
      })
      setLoading(false)
    } catch (error) {
      showToast({
        content: `Ha ocurrido un error reenviando el correo electronico`,
        title: `Ha ocurrido un error`,
        type: 'error',
      })
    }
  }

  const message = `Enviamos un correo a ${
    props?.location?.state?.email || ''
  } con las instrucciones para restablecer contraseña`

  const emailImage =
    'https://images.ctfassets.net/16npdkkoi5mj/3w3FT0mHzzLuLcDRXDppod/038f39a70354cacc89688b81a54a118d/mailenviado.png'

  return (
    <Layout
      currentCity={currentCity}
      title="Cambiar contraseña"
      navbar={isDesktop ? NavBarStaticPage : UserMenuMobileNavbar({ city: currentCity })}
    >
      <PageContainer cardMaxWidthDesktop="723px" cardWidthDesktop="723px" cardMinWidthDesktop="723px">
        <PageWrapper>
          <div className={`${cssPrefix}top-bar`}>
            {isDesktop && (
              <Anchor onClick={() => navigate(`/${currentSlug}`)} className={`${cssPrefix}top-bar-icon`}>
                <Icon iconId="arrow_left" size="24" />
              </Anchor>
            )}
          </div>
          <div className={`${cssPrefix}container`}>
            <span className={`${cssPrefix}indication`}>{message}</span>
            <img src={emailImage} className={`${cssPrefix}email-image`} alt="confirm" />
            <span className={`${cssPrefix}indication`}>
              ¿No encuentras el correo?
              <br />
              Revisa la carpeta de “No deseados”
            </span>
            <Button
              data-test="signin-submit"
              className={`${cssPrefix}form-submit-button-white`}
              whileTap={{ scale: 0.9 }}
              onClick={() => onSubmit()}
              disabled={loading}
              isDisabled={loading}
            >
              Reenviar Correo
            </Button>
          </div>
        </PageWrapper>
      </PageContainer>
    </Layout>
  )
}

export default withPageTransition(ResetPassword)
